<template>
  <section class='create-page xa-view xa-container'>
    <main class='form-main xa-flex xa-container'>
      <router-view
        ref='form'
        @qrCode='onShowQrCode'
        @submit='onFormSubmit'
        @selectProduct='onSelectProduct'
        @list='gotoList'
      ></router-view>
    </main>
    <router-view name='foot' @submit='onSubmit'></router-view>
    <el-dialog
      v-if='displaySelector'
      class='product-dialog'
      title='选择适用商品'
      :visible.sync='isShowSelectProduct'
    >
      <Selector
        ref='selectProductVm'
        :params='selectProductParams'
        @submit='onProductSubmit'
        @show-product='onShowCheckedProduct'
        @close='isShowSelectProduct = false'
      />
    </el-dialog>
    <el-dialog
      class='product-select-dialog'
      :visible.sync='isShowCheckedProduct'
    >
      <template #title>
        <span>已选商品（{{ getProductNum }}）</span>
      </template>
      <SelectedProduct
        ref='checkedProduct'
        :tableData='checkedProducts'
        :params='checkedProductParams'
        @changeCheckedProduct='changeCheckedProduct'
        @close='isShowCheckedProduct = false'
      ></SelectedProduct>
    </el-dialog>
    <DiaplayQrCode :visible.sync='isShowQrCode' :content='qrCodeContent' />
  </section>
</template>
<script>
import * as Apis from '@target/apis/coupon'
import SelectorMixin from './mixins/Selector'

export default {
  mixins: [SelectorMixin],
  provide: {
    Apis
  },
  components: {
    DiaplayQrCode: () => import('./components/DiaplayQrCode.vue')
  },
  data() {
    return {
      isShowQrCode: false,
      qrCodeContent: '',
      needFootBtn: true,
      showChild: true,
      checkedProducts: []
    }
  },
  computed: {
    getProductNum: function() {
      return this.checkedProducts.length
    }
  },
  methods: {
    triggleRefresh() {
      this.showChild = false
      this.$nextTick(() => {
        this.showChild = true
      })
    },
    onShowQrCode(guid) {
      this.isShowQrCode = !this.isShowQrCode
      this.qrCodeContent =
        (window.location.host.includes('agri.xaircraft.com')
          ? 'https://agri.xaircraft.com/'
          : 'https://xct.xair.cn/') +
        'pm/v4/#/coupon/system/' +
        guid
    },
    // 显示已选商品
    onShowCheckedProduct(json) {
      this.checkedProducts = json.products
      this.isShowCheckedProduct = true
    },

    // 改变已选中数据
    changeCheckedProduct(products) {
      // 修改产品选择框中已选中的选项
      this.$nextTick(() => {
        if (this.$refs.selectProductVm && this.$refs.selectProductVm.updateTableCheck) {
          this.$refs.selectProductVm.updateTableCheck(products || [])
        }
      })
      // 关闭当前窗口
      this.isShowCheckedProduct = false
    },

    /**
     * 弹窗提示
     */
    askMsg(message) {
      const confirmButtonText = this.$t('Confirm')
      const cancelButtonText = this.$t('Cancel')
      const doingText = this.$t('执行中...')
      const successText = this.$t('SuccessOperation')
      return new Promise(resolve => {
        this.$msgbox({
          title: '',
          message: message,
          showCancelButton: true,
          confirmButtonText,
          cancelButtonText,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = doingText
              resolve(result => {
                done()
                instance.confirmButtonLoading = false
                if (result) {
                  this.$message({
                    type: 'success',
                    showClose: true,
                    message: successText
                  })
                }
              })
            } else {
              done()
            }
          },
          callback: (action, instance) => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = confirmButtonText
          }
        })
      })
    },
    async onFormSubmit(data) {
      const done = await this.askMsg('确定要提交？')
      try {
        const { guid } = await Apis.createCoupon(data)
        if (
          Object.keys(this.$route.query).length === 0 ||
          this.$route.name.includes('coupon-copy')
        ) {
          // 新建
          this.$router.replace({
            name: 'coupon-result',
            query: {
              guid
            }
          })
        } else {
          this.$router.replace({
            query: {
              guid: guid
            }
          })
          this.triggleRefresh()
        }
        done(true)
      } catch (error) {
        done()
        setTimeout(() => {
          this.$confirm(error.msg || error.message || error, '提示', {
            showCancelButton: false,
            confirmButtonText: '确定',
            type: 'error'
          })
        }, 0)
      }
    },
    onSubmit() {
      this.$refs.form.submitForm()
    },
    onCancel() {
      this.gotoList()
    },
    gotoList() {
      this.$router.replace({
        path: '/home/voucher/datatable',
        query: {
          src: '/main/operation/coupon/index_config',
          params: '[]',
          title: '优惠券列表',
          nav: 1
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.create-page {
  position: relative;
  min-height: 100vh;
}

.form-main {
  margin: 20px 12px 0;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #999;

  /deep/ .form__title {
    margin-bottom: 20px;
    line-height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  /deep/ .el-form-wrap {
    min-width: 680px;
  }

  /deep/ .el-form-item {
    width: 780px;
  }

  /deep/ .form-radio-group.xa-view .el-radio {
    & + .el-radio {
      margin-top: 8px;
    }

    display: flex;
    align-items: center;
    line-height: 36px;
  }

  /deep/ .form-radio-group.xa-view .el-radio__label {
    padding-left: 10px;
  }
}

.product-dialog {
  /deep/ .el-dialog {
    min-width: 920px;
  }

  /deep/ .el-dialog__header {
    padding: 16px 15px;
    border-bottom: 1px solid #eee;
  }

  /deep/ .el-dialog__body {
    padding: 10px 15px 0;
  }
}

.product-select-dialog {
  /deep/ .el-dialog {
    min-width: 920px;
  }

  /deep/ .el-dialog__header {
    padding: 16px 15px;
    border-bottom: 1px solid #eee;
  }

  /deep/ .el-dialog__body {
    padding: 10px 15px 0;
  }
}

.form-head,
.form-foot {
  padding: 0 15px;
  height: 40px;
  background-color: #fff;
  flex-shrink: 0;
}

.form-foot {
  box-shadow: 0px 3px 6px 0px #000;
  justify-content: center;
}
</style>
