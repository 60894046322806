import request from '@/apis'

export function searchUser(data) {
  return request({ url: '/common/common/search_user', data })
}

// 获取系统定义的优惠券列表
export function getCouponList(data) {
  return request({ url: '/main/operation/coupon/index_data', data })
}

/**
 * 提交优惠券
 * @param {*} data
 */
export function createCoupon(data) {
  return request({
    method: 'post',
    url: '/main/operation/coupon/edit_coupon',
    data
  })
}

/**
 * 获取优惠卷定义信息
 * @param {*} data
 */
export function getCouponDetail(data) {
  return request({
    method: 'get',
    url: '/main/operation/coupon/get_detail',
    data
  })
}

/**
 * 获取商品信息
 * @param {*} use_sys
 */
export function getLimiProductList(data) {
  return request({
    method: 'get',
    url: '/main/operation/coupon/get_product_list',
    data
  })
}

/**
 * 获取商品分类信息
 * @param {*} use_sys
 */
export function getLimiProductCategory(data) {
  return request({
    method: 'get',
    url: '/main/operation/coupon/get_product_category_list',
    data
  })
}
export async function getCategoryListOptions(data) {
  function transformFilterOptions(data) {
    return data.map(item => {
      if (item.son && item.son.length) {
        return {
          value: item.guid,
          label: item.name,
          children: transformFilterOptions(item.son)
        }
      } else {
        return {
          value: item.guid,
          label: item.name
        }
      }
    })
  }
  const result = await getLimiProductCategory(data)
  return transformFilterOptions(result)
}
/**
 * 适用平台列表
 */
export async function getSysList() {
  const data = await request({
    method: 'get',
    cache: true,
    url: '/main/operation/coupon/get_sys_list'
  })
  return Object.keys(data).map(key => {
    return {
      label: data[key],
      value: key
    }
  })
}

/**
 * 批量发券-检查用户信息
 * @param {Array} coupons [{'coupon_guid'=>'优惠券guid','mobile'=>'13333333333','cnt'=>2}]
 */
export async function checkCouponAndUser(coupons) {
  function valStr(str = '') {
    if (typeof str === 'string') return str.replace(/↵\n\r/g, '')
    return str
  }
  return request({
    method: 'post',
    url: '/main/operation/coupon/check_user',
    headers: {
      'Content-type': 'application/json'
    },
    data: coupons.map(item => {
      let coupon_guid = item['优惠券编号']
      let mobile = item['用户手机号']
      let cnt = item['张数']
      return {
        coupon_guid: valStr(coupon_guid),
        mobile: valStr(mobile),
        cnt: valStr(cnt) || 1
      }
    })
  })
}

/**
 * 批量发券-检查用户信息
 * @param {Array} coupons [{'coupon_guid'=>'优惠券guid','mobile'=>'13333333333','cnt'=>2}]
 */
export async function sendCoupon(coupons) {
  return request({
    method: 'post',
    url: '/main/operation/coupon/send_coupon',
    headers: {
      'Content-type': 'application/json'
    },
    data: coupons
  })
}

const config = {
  1: 'https://crm.xag.cn/#/product-manage/single-list',
  2: 'https://agri.xaircraft.com/pc/#/home/mall/'
}

export function getManageUrl(param) {
  if (param.use_sys) {
    return config[param.use_sys]
  }
}
