export default {
  components: {
    Selector: () => import('@target/views/coupon/form/components/Selector.vue'),
    SelectedProduct: () => import('@target/views/coupon/form/components/SelectedProduct.vue')
  },
  data() {
    return {
      isShowSelectProduct: false,
      isShowCheckedProduct: false,
      selectProductResolve: null,
      checkedProductResolve: null,
      displaySelector: true,
      selectProductKey: '',
      selectProductParams: {},
      checkedProductParams: {}
    }
  },
  methods: {
    async onSelectProduct(resolve) {
      this.selectProductParams = resolve.params
      if (this.selectProductKey !== resolve.key && this.selectProductKey) {
        this.selectProductKey = resolve.key
        await this.reBuildSelector()
      }
      this.isShowSelectProduct = true
      this.selectProductResolve = resolve
      this.$nextTick(() => {
        if (this.$refs.form && this.$refs.form.ruleForm && this.$refs.form.ruleForm.product) { // 更新产品选择的数据
          if (this.$refs.selectProductVm && this.$refs.selectProductVm.updateTableCheck) {
            let product = this.$refs.form.ruleForm.product
            product.forEach((v) => {
              return v._checked = true
            })
            this.$refs.selectProductVm.updateTableCheck(product || [])
          }
        }
      })
    },
    reBuildSelector() {
      this.displaySelector = false
      return new Promise(resolve => {
        setTimeout(() => {
          this.displaySelector = true
          resolve()
        }, 0)
      })
    },
    onProductSubmit(results) {
      this.isShowSelectProduct = false
      this.selectProductResolve(results)
    }
  }
}
